<template>
 <router-view></router-view>
 <p>Pa sto bi gdje zaluto a?</p>
 <p>Aloooooooo</p>
</template>

<script>
 export default {
     name: '404',
 }
</script>
